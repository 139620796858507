<script setup lang="ts">
import {initChatChannels, initPusher} from '@/services/pusher'
import {getAccessToken} from '@/services/authUser'
import useAuthenticate from '@/composables/useAuthenticate'
import MabellNotifications from "@/components/MabellNotifications.vue";

import { askForNotificationPermission } from "@/utils/browserNotifyNewChat";
import {computed, onMounted} from 'vue'
import {useStore} from 'vuex'
import {Tubs} from '@/enum/chat'

const {authenticate} = useAuthenticate()
const store = useStore()
const companyBranchId = computed(() => store.state.adminUser.user?.company_branch_id)
const chats = computed(() => store.state.chat.chats[Tubs.mine])

const token = getAccessToken()
onMounted(async () => {
  askForNotificationPermission()
  if (token && !store.getters['adminUser/getUserId']) {
    await authenticate(token)
    await store.dispatch('chat/fetchList', Tubs.mine)
    if (companyBranchId.value) {
      await initPusher(token, companyBranchId.value).then(() => {
          if (chats.value) {
            initChatChannels(chats.value)
          }
        }
      )
    }
  }
})
</script>

<template>
  <div class="app-container">
    <router-view/>
    <mabell-notifications/>
  </div>
</template>

<style lang="scss">
.app-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
</style>
