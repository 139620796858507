import {ChatStatisticsDataResponse} from "@/types/ChatStatisticsDataResponse";

export const renderChart = (
    categories: string[] = [],
    values: number[] = [],
    axisBorderColor = "#FF1654",
    labelsColor = "#FF1654",
    title = "",
    titleColor = "#FF1654"
): ChatStatisticsDataResponse => {
    return ({
        options: {
            chart: {
                id: "chat-static-data",
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: categories,
            },
            yaxis: [
                {
                    position: "bottom",
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                        color: axisBorderColor
                    },
                    labels: {
                        rotate: 0,
                        rotateAlways: false,
                        formatter: function (val) {
                            return val.toFixed(0);
                        },
                        style: {
                            colors: labelsColor
                        },
                    },
                    title: {
                        text: title,
                        style: {
                            color: titleColor
                        }
                    }
                }
            ]
        },
        series: {
            name: '',
            data: values
        }
    })
}