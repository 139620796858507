import axios from '@/services/axiosApi'
import { Tubs } from '@/enum/chat'
import {formatDateToYMDTHisU} from "@/utils/dataTimeFormatter";

type StatusType = Tubs.mine | Tubs.unassigned | Tubs.finished;

interface ChatItem {
    id: string;
}

type ChatStore = {
    chats: {
        ACCEPTED : Array<any>,
        PENDING : Array<any>,
        FINISHED : Array<any>,
    };
    active: object| null,
    loaded: boolean,
}

export const chat = {
  namespaced: true,
  state: () :ChatStore => ({
    chats: {
      ACCEPTED: [],
      PENDING: [],
      FINISHED: []
    },
    active: null,
    loaded: false
  }),
  getters: {
    getUnassigned: (state: ChatStore) => state.chats.PENDING,
    getChats: (state: ChatStore) => state.chats,
    getActive: (state: ChatStore) => state.active,
    getChatById: (state:ChatStore) => (id: number) => {return state.chats.ACCEPTED.find(chat => chat.id === id)},
  },
  mutations: {
    setUnread(state: ChatStore, payload: {chatId: string, status: StatusType}) {
      const val = state.chats[payload.status].find((chat)=> chat.id === payload.chatId);
      if (val) {
        val.has_unread = true
      }
    },
    readChat(state: ChatStore, payload: {chatId: string, status: StatusType}) {
      const val = state.chats[payload.status].find((chat)=> chat.id === payload.chatId);
      if (val) {
        val.has_unread = false
      }
    },
    setChats (state: ChatStore, payload: { responseData: [], status : StatusType }) {
      state.chats[payload.status] = payload.responseData
    },
    setActive (state: ChatStore, chat: object) {
      state.active = chat
    },
    resetActive (state: ChatStore) {
      state.active = null
    },
    setLastMessage (state: ChatStore, payload: { chatId: string, activeStatus: Tubs, message: string, time: string }) {
      const chat = state.chats[payload.activeStatus].filter(chat => chat.id === payload.chatId).pop()
      if(chat) {
        const date = new Date(payload.time)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0')
        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        const seconds = date.getSeconds().toString().padStart(2, '0')

        chat.last_message = payload.message
        chat.last_message_date = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      }
    },
    addPendingChats (state: ChatStore, chat: object[]) {
      state.chats[Tubs.unassigned].push(chat)
    },
    setLoaded (state: ChatStore, value: boolean) {
      state.loaded = value
    }
  },
  actions: {
    fetchList: async (
      { dispatch, commit }:{ dispatch: (...args: any[]) => void, commit: (...args: any[]) => void },
      status:StatusType
    ) => {
      await axios.post('admin/chat/list', { status })
        .then(response => {
          if (response.data) {
            commit('setChats', { responseData: response.data.data, status })
            commit('setLoaded', true)
          }
        })
        .catch(err => err)
    },
    acceptChat: async ({ commit }: { commit: (...args: any[]) => void }, chat:ChatItem) => {
      await axios.post(`admin/${chat.id}/accept`)
    },
    finishChat: async ({ commit }: { commit: (...args: any[]) => void }, chat:ChatItem) => {
      await axios.post(`admin/${chat.id}/finish-chat`)
    },
    messageSeen: async ({ commit }: { commit: (...args: any[]) => void }, payload: {
      chatId: string,
    }) => {
      axios.post('admin/' + payload.chatId + '/message-seen', {
        'date_time': formatDateToYMDTHisU(new Date())
      })
          .then(response => {
            // console.log(response, 'message-seen')
          })
    },
  }
}
