import axios from '@/services/axiosApi'
import moment from 'moment'

type MessageStore = {
    messages: Array<any>,
}

export const chatMessages = {
  namespaced: true,
  state: () :MessageStore => ({
    messages: []
  }),
  mutations: {
    setMessages (state: MessageStore, responseData: []) {
      state.messages = responseData
    },
    resetMessages (state: MessageStore) {
      state.messages = []
    },
    addMessage (state: MessageStore, data: object) {
      state.messages.push(data)
    },
    updateMessage (state: MessageStore, data: {index: number, payload: object}) {
      state.messages[data.index] = data.payload
    }
  },
  actions: {
    fetchChatMessages: async ({ commit }: { commit: (...args: any[]) => void }, { chatId }: {chatId:string}) => {
      await axios.get('admin/messages/' + chatId + '/list')
        .then(response => {
          commit('setMessages', response.data.data)
        })
        .catch(err => err)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendMessage: async ({ commit }: { commit: (...args: any[]) => void }, payload: {
            // eslint-disable-next-line camelcase
            chat_id : string,
            // eslint-disable-next-line camelcase
            parent_message_id : string,
            // eslint-disable-next-line camelcase
            from_id : string,
            // eslint-disable-next-line camelcase
            to_id : string
            message : string,
            index: number,
            time: string
        }) => {
      axios.post('admin/send-message', {
        chat_id: payload.chat_id,
        // eslint-disable-next-line camelcase
        parent_message_id: payload.parent_message_id,
        // eslint-disable-next-line camelcase
        from_id: payload.from_id,
        // eslint-disable-next-line camelcase
        message: payload.message,
        sent_date: moment(payload.time).format('YYYY-MM-DD HH:mm:ss')
      }).then(response => {
        if (response.status === 200) {
          commit('updateMessage', { index: payload.index, payload: response.data.data })
        }
      }).catch(e => console.error(e))
    }
  }
}
