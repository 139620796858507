import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import VueApexCharts from "vue3-apexcharts"
import 'vue3-emoji-picker/css'
import 'vue3-toastify/dist/index.css'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'
import VueTailwindDatepicker from "vue-tailwind-datepicker"
import VueMultiselect from 'vue-multiselect'
import 'vue-tel-input/vue-tel-input.css'
import VueTelInput from 'vue-tel-input'

createApp(App)
    .use(store)
    .use(router)
    .use(VueTailwindDatepicker as any)
    .use(VueApexCharts)
    .use(Vue3Toastify, {
        autoClose: 3000,
    } as ToastContainerOptions)
    .use(VueTelInput as any)
    .component('vue-multiselect', VueMultiselect)
    .mount('#app')
