import {getAccessToken, removeAuthToken} from '@/services/authUser';
import axios, {AxiosResponse} from 'axios'
import {toast} from "vue3-toastify"

const axiosApi = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

const setDefaultHeaders = () => {
    axiosApi.defaults.headers.common['Content-Type'] = 'application/json';
    axiosApi.defaults.headers.common['Accept'] = 'application/json';
}

export const setAuthHeader = (token: string) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const apiAuthenticate = () => {
    const token = getAccessToken();
    if (token) {
        setAuthHeader(token);
    }
}

setDefaultHeaders()
apiAuthenticate()

axiosApi.interceptors.response.use(
    (response: AxiosResponse<any, any> | undefined): any | undefined => {
        if (response) {
            if (response.data.message) {
                toast.success(response.data.message, {
                    theme: 'colored',
                    autoClose: 3000,
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
            return response;
        }
    },
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    removeAuthToken()
                    break;
                default:
                    toast.error(error.response.data.errors
                        ? Object.values(error.response.data.errors).join('\n')
                        : 'An unexpected error occurred', {
                        autoClose: 15000,
                        theme: 'colored',
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
            }
        } else {
            removeAuthToken()
        }
});

export default axiosApi;
