import { ActionContext } from 'vuex';
import axios from '@/services/axiosApi';
import { AxiosResponse} from "axios";
import { ChatStatisticsDataResponse } from '@/types/ChatStatisticsDataResponse';
import { ChatStatisticsDataState } from "@/interfaces/ChatStatisticsDataState";
import { renderChart } from "@/utils/addStars";
import {OrderStatisticsDataResponse} from "@/types/OrderStatisticsDataResponse";

export const chatStatistics = {
    state: (): ChatStatisticsDataState => ({
        chatData: null,
        reviewData: null,
        orderData: null,
    }),
    getters: {
        GET_CHAT_STATISTICS_DATA: (state: ChatStatisticsDataState): ChatStatisticsDataResponse | null => {
            return state.chatData;
        },
        GET_REVIEW_STATISTICS_DATA: (state: ChatStatisticsDataState): ChatStatisticsDataResponse | null => {
            return state.reviewData;
        },
        GET_ORDERS_STATISTICS_DATA: (state: ChatStatisticsDataState): OrderStatisticsDataResponse | null => {
            return state.orderData;
        },
    },
    mutations: {
        SET_CHAT_STATISTICS_DATA(state: ChatStatisticsDataState, data: ChatStatisticsDataResponse) {
            state.chatData = data;
        },
        SET_REVIEW_STATISTICS_DATA(state: ChatStatisticsDataState, data: ChatStatisticsDataResponse) {
            state.reviewData = data;
        },
        SET_ORDERS_STATISTICS_DATA(state: ChatStatisticsDataState, data: OrderStatisticsDataResponse) {
            state.orderData = data;
        },
    },
    actions: {
        async fetchChatStatisticsData({ commit }: ActionContext<any, any>, action: string): Promise<void> {
            try {
                const response: AxiosResponse<object> = await axios.get(`/admin/${action}/statistics`);
                const categories: string[] = Object.keys((response.data as any)?.data);
                const values: number[] = Object.values((response.data as any)?.data);
                const data: ChatStatisticsDataResponse = renderChart(categories, values, "#FF1654", "#FF1654", "COUNT", "#FF1654");
                if (action == 'chats') {
                    commit('SET_CHAT_STATISTICS_DATA', data);
                } else {
                    data.options.xaxis.categories.forEach((value, index) => {
                       return (data.options.xaxis.categories[index] as string[] | string) = `Star ${value}`
                    })
                    commit('SET_REVIEW_STATISTICS_DATA', data);
                }
            } catch (e) {
                console.error('Error fetching chart data:', e);
            }
        },
        async fetchOrderStatisticsData({ commit }: ActionContext<any, any>): Promise<void> {
            try {
                const response: AxiosResponse<object> = await axios.get(`/admin/orders/statistics`);
                const categories: string[] = Object.keys((response.data as any)?.data);
                const values: number[] = Object.values((response.data as any)?.data);
                const data: OrderStatisticsDataResponse = renderChart(categories, values, "#FF1654", "#FF1654", "COUNT", "#FF1654");
                commit('SET_ORDERS_STATISTICS_DATA', data);
            } catch (e) {
                console.error('Error fetching chart data:', e);
            }
        }
    }
};