type NotificationType = {
    seen:boolean,
    message: string,
    title: string|null,
}

interface NotificationsStore {
    notifications: Array<NotificationType>
}
export const notifications = {
    namespaced: true,
    state: () : NotificationsStore => ({
        notifications:[],
    }),
    getters: {
        getNotifications(state:NotificationsStore): Array<NotificationType> {
            return state.notifications;
        }
    },
    mutations: {
        addNotification: (state: NotificationsStore, data:NotificationType) => {
            state.notifications.push(data);
        },
    },
    actions: {

    }
}
