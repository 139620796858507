import {createEcho} from '@/services/createEcho'
import store from '@/store'
import {Tubs} from '@/enum/chat'
import eventBus from '@/services/eventBus'
import {browserNotifyNewChat, browserNotifyNewOrder} from "@/utils/browserNotifyNewChat";

const privateChannelListen = (channelName: string, event: string, cb: (dt: any) => void, type = '') => {
    window.Echo.private(channelName)
        .listen(event, (data: any) => {
            cb(data)
        })
        .error((error:any) => {
            console.log("error ws " + channelName, error)
        })
        .subscribed(()=> {
            // console.log("subscribed " + channelName)
        })
}

const initPusher = async (token: string, companyBranchId: string) => {
    window.Pusher = require('pusher-js')
    window.Echo = createEcho(token)

    privateChannelListen(
        'message-to-all.' + companyBranchId,
        '.message-to-all',
        async (data: any) => {
            await store.dispatch('chat/fetchList', Tubs.unassigned)
            store.commit('chatTabs/incrementUnassigned')
            browserNotifyNewChat()
        })

    privateChannelListen(
        'chat-accepted.' + companyBranchId,
        '.chat-accepted',
        (data: any) => {
            store.commit('chatTabs/decrementUnassigned')
            store.commit('chatTabs/incrementMine')
        })

    privateChannelListen(
        'restaurant-orders.' + companyBranchId,
        '.restaurant-orders',
        (data: any) => {
           store.commit('addOrder', data.order)
            browserNotifyNewOrder()
        })
}

const listenToChat = (chat: {id: string}) => {
    const channelName = 'chat.' + chat.id
    privateChannelListen(
    channelName,
    '.chat',
    (data: any) => {
      play(data)
      if (data.messageResource && data.messageResource.to_id === store.getters['adminUser/getUserId']) {
        if (store.getters['chat/getActive'] && chat.id === store.getters['chat/getActive'].id) {
          store.commit('chatMessages/addMessage', data.messageResource)
          store.dispatch('chat/messageSeen', {chatId: chat.id})
        } else {
          store.commit('chat/setUnread', {chatId: chat.id, status: store.getters['chatTabs/getActiveStatus']})
        }

        const message = data.messageResource.translated_message || data.messageResource.original_message

        store.commit('chat/setLastMessage', {
          chatId: chat.id,
          activeStatus: Tubs.mine,
          message,
          time: data.messageResource.sent_date
        })

        // Throw events
        eventBus.emit('notification', {
          message,
          seen: false,
          title: data.messageResource.from_name,
          chat_id: data.messageResource.chat_id
        })
        eventBus.emit('messageListUpdated')
      }
    },
      'listenToChat'
  )
}

const play = (data: any) => {
    if (data?.messageResource?.from_id !== store.getters['adminUser/getUserId']) {
        const player: HTMLAudioElement | null = document.querySelector('#player')
        if (player && !player.muted) {
            player.play()
        }
    }
}

const initChatChannels = (chats: Array<{ id: string }>) => {
    for (const chat of chats) {
        listenToChat(chat)
    }
}

export {
    initPusher,
    privateChannelListen,
    listenToChat,
    initChatChannels
}
