import Swal, { SweetAlertResult } from "sweetalert2";

interface ModalOptions {
    title?: string;
    text?: string;
    confirmButtonText?: string;
    showCancelButton?: boolean;
    allowOutsideClick?: boolean;
    allowEscapeKey?: boolean;
}

export const showSwalModal = async (options: ModalOptions = {}): Promise<SweetAlertResult<ModalOptions>> => {
    return await Swal.fire(options);
};