import router from "@/router";
import store from "@/store";
import {Tubs} from "@/enum/chat";

function browserNotify(message:string, onClickCB: (...args: any[]) => void ) {
  if (Notification.permission !== 'granted')
    Notification.requestPermission();
  else {
    const notification = new Notification('Mabell', {
      icon: require('../assets/logo.svg'),
      body: message,
    });

    if(onClickCB) {
      notification.onclick = function() {onClickCB()};
    }
  }
}

export function browserNotifyNewChat() {
  browserNotify('You have a new chat request.', () => {
    store.commit('chatTabs/setActiveStatus', Tubs.unassigned);
    window.open(router.options.history.base + router.resolve('chat').fullPath);
  })
}

export function browserNotifyNewOrder() {
  browserNotify('You have a new pending order.', () => {
    store.commit('chatTabs/setActiveStatus', Tubs.unassigned);
    window.open(router.options.history.base + router.resolve('pending-orders').fullPath);
  })
}

export function askForNotificationPermission() {
  if (Notification.permission !== 'granted')
    Notification.requestPermission();
}

