<script lang="ts" setup>
import {defineProps} from 'vue'
import type { ToastOptions } from 'vue3-toastify';
import type { PropType } from 'vue';
import store from '@/store'
import router from "@/router";

  const props = defineProps( {
    closeToast: Function as PropType<(e?: MouseEvent) => void>,
    toastProps: Object as PropType<ToastOptions>,
    message: String,
    title: String,
    chat: String
  })


  const getChat = store.getters["chat/getChatById"]
  const chat = getChat(props.chat)

  const onToastClick = () => {
    store.commit('chat/setActive', chat)
    setTimeout(() => {
      router.push({name: 'chat'})
    })
  }
</script>

<template>
  <div class="cursor-pointer" @click="onToastClick">
    <p class="font-bold">{{ title }}</p>
    <p class="text-sm py-1">{{ message }}</p>
  </div>
</template>
