import { Tubs } from '@/enum/chat'
import axios from '@/services/axiosApi'

type StatusType = Tubs.mine | Tubs.unassigned | Tubs.finished;

type ChatStore = {
    tabs: Array<object>,
    counts: {
        ACCEPTED : number,
        PENDING : number,
        FINISHED : number,
    },
    activeStatus: StatusType
}

export const chatTabs = {
  namespaced: true,
  state: () :ChatStore => ({
    tabs: [
      {
        name: 'Mine',
        status: Tubs.mine
      },
      {
        name: 'Pending',
        status: Tubs.unassigned
      },
      {
        name: 'Finished',
        status: Tubs.finished
      }
    ],
    counts: {
      ACCEPTED: 0,
      PENDING: 0,
      FINISHED: 0
    },
    activeStatus: Tubs.mine
  }),
  getters: {
    getActiveStatus: (state: ChatStore) => state.activeStatus
  },
  mutations: {
    setActiveStatus (state: ChatStore, tab: StatusType) {
      state.activeStatus = tab
    },
    setCounts (
      state: ChatStore,
      payload: { responseData: { accepted: number, finished: number, pending: number }}
    ) {
      state.counts.ACCEPTED = payload.responseData.accepted
      state.counts.PENDING = payload.responseData.pending
      state.counts.FINISHED = payload.responseData.finished
    },
    incrementUnassigned (state: ChatStore) {
      state.counts.PENDING = state.counts.PENDING + 1
    },
    incrementMine (state: ChatStore) {
      state.counts.ACCEPTED = state.counts.ACCEPTED + 1
    },
    incrementFinished (state: ChatStore) {
      state.counts.FINISHED = state.counts.FINISHED + 1
    },
    decrementMine (state: ChatStore) {
      if (state.counts.ACCEPTED > 0)
        state.counts.ACCEPTED = state.counts.ACCEPTED - 1
    },
    decrementUnassigned (state: ChatStore) {
      if (state.counts.PENDING > 0)
        state.counts.PENDING = state.counts.PENDING - 1
    }
  },
  actions: {
    fetchCounts: async ({ commit }: { commit: (...args: any[]) => void }, status:StatusType) => {
      try {
        await axios.get('admin/chat/count')
          .then(response => {
            if (response?.data) {
              commit('setCounts', { responseData: response.data.data })
            }
          })
      }catch (e) {
        console.error(e)
      }
    }
  }
}
