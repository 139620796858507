function formatDateToYMDTHisU(date: Date) {
  const pad = (num: unknown, size: number) => {
    let s = String(num);
    while (s.length < size) s = "0" + s;
    return s;
  };

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1, 2); // Months are zero-based
  const day = pad(date.getDate(), 2);
  const hours = pad(date.getHours(), 2);
  const minutes = pad(date.getMinutes(), 2);
  const seconds = pad(date.getSeconds(), 2);
  const milliseconds = pad(date.getMilliseconds(), 3);
  const microseconds = pad(+milliseconds * 1000, 6); // Convert milliseconds to microseconds

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${microseconds}Z`;
}


export {
  formatDateToYMDTHisU
}
